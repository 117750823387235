import React, { useState } from 'react';
import { ChevronLeft, Mail, User, Phone, AlertCircle, Lock, Eye, EyeOff, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AuthenticationModal from './AuthenticationModal';

const FindCredentials = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState('email');
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === 'email') {
      if (!formData.name || !formData.phone) {
        setError('모든 필드를 입력해주세요.');
        return;
      }
      console.log('이메일 찾기:', formData);
      setSuccessMessage('등록된 전화번호로 이메일이 전송되었습니다.');
      setShowSuccessModal(true);
    } else if (mode === 'password') {
      if (!formData.email) {
        setError('이메일을 입력해주세요.');
        return;
      }
      setShowAuthModal(true);
    }
  };

  const handleAuthModalClose = () => {
    setShowAuthModal(false);
  };

  const handleAuthSuccess = () => {
    setShowAuthModal(false);
    setSuccessMessage('이메일 인증이 완료되었습니다.');
    setShowSuccessModal(true);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setPasswordError('비밀번호가 일치하지 않습니다.');
      return;
    }
    console.log('비밀번호 재설정:', formData.newPassword);
    setSuccessMessage('비밀번호가 성공적으로 변경되었습니다.');
    setShowResetPasswordModal(false);
    setShowSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    if (mode === 'password') {
      navigate('/');
    }
  };

  const renderForm = () => {
    switch (mode) {
      case 'email':
        return (
          <>
            <InputField
              icon={<User />}
              id="name"
              label="이름"
              placeholder="이름을 입력해 주세요"
              onChange={handleInputChange}
              value={formData.name}
            />
            <InputField
              icon={<Phone />}
              id="phone"
              label="전화번호"
              placeholder="전화번호를 입력해 주세요"
              onChange={handleInputChange}
              value={formData.phone}
            />
          </>
        );
      case 'password':
        return (
          <InputField
            icon={<Mail />}
            id="email"
            label="이메일"
            placeholder="이메일을 입력해 주세요"
            onChange={handleInputChange}
            value={formData.email}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-md p-8 mx-auto">
      <div className="flex items-center justify-between mb-6">
        <ChevronLeft
          className="cursor-pointer"
          size={24}
          onClick={() => navigate('/')}
        />
        <h2 className="text-lg font-bold flex-grow text-center mr-6">
          {mode === 'email' ? '이메일 찾기' : '비밀번호 찾기'}
        </h2>
      </div>

      <div className="mb-6 flex justify-center space-x-4">
        <button
          className={`px-4 py-2 rounded-full ${mode === 'email' ? 'bg-[#4ABC85] text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setMode('email')}
        >
          이메일 찾기
        </button>
        <button
          className={`px-4 py-2 rounded-full ${mode === 'password' ? 'bg-[#4ABC85] text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setMode('password')}
        >
          비밀번호 찾기
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {renderForm()}

        {error && (
          <p className="text-[#F9344B] text-sm flex items-center">
            <AlertCircle size={16} className="mr-1" />
            {error}
          </p>
        )}

        <button 
          type="submit" 
          className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg"
        >
          {mode === 'email' ? '이메일 찾기' : '비밀번호 찾기'}
        </button>
      </form>

      <AuthenticationModal
        isOpen={showAuthModal}
        onClose={handleAuthModalClose}
        type="email"
        onSuccess={handleAuthSuccess}
      />

      {showResetPasswordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-2xl w-[72%] max-w-sm">
            <h2 className="text-lg font-bold mb-4">새 비밀번호 설정</h2>
            <form onSubmit={handleResetPassword} className="space-y-4">
              <InputField
                icon={<Lock />}
                id="newPassword"
                label="새 비밀번호"
                placeholder="새 비밀번호를 입력해 주세요"
                type={showPassword ? "text" : "password"}
                onChange={handleInputChange}
                value={formData.newPassword}
                rightIcon={
                  showPassword ? (
                    <Eye onClick={() => setShowPassword(false)} className="cursor-pointer" size={20} />
                  ) : (
                    <EyeOff onClick={() => setShowPassword(true)} className="cursor-pointer" size={20} />
                  )
                }
              />
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <label htmlFor="confirmPassword" className="block text-sm font-normal text-[#231F20]">비밀번호 확인</label>
                  {passwordError && (
                    <span className="text-xs text-[#F9344B] flex items-center">
                      <AlertCircle size={12} className="mr-1" />
                      {passwordError}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <Lock className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${formData.confirmPassword ? 'text-gray-900' : 'text-gray-400'}`} size={20} />
                  <input
                    id="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="비밀번호를 다시 입력해 주세요"
                    onChange={handleInputChange}
                    value={formData.confirmPassword}
                    className={`w-full h-[60px] pl-10 pr-10 bg-[#EEEEEE] border border-gray-300 rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-gray-400 focus:ring-[#4ABC85] caret-[#4ABC85]`}
                  />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    {showConfirmPassword ? (
                      <Eye onClick={() => setShowConfirmPassword(false)} className="cursor-pointer" size={20} />
                    ) : (
                      <EyeOff onClick={() => setShowConfirmPassword(true)} className="cursor-pointer" size={20} />
                    )}
                  </div>
                </div>
              </div>
              <button 
                type="submit" 
                className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg"
              >
                비밀번호 변경
              </button>
            </form>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-2xl w-[72%] max-w-sm">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold">알림</h2>
              <X onClick={() => {
                setShowSuccessModal(false);
                if (mode === 'password') {
                  setShowResetPasswordModal(true);
                }
              }} className="cursor-pointer" />
            </div>
            <p className="text-center mb-6">{successMessage}</p>
            <button 
              onClick={() => setShowSuccessModal(false)}
              className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg"
            >
              확인
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const InputField = ({ icon, id, label, placeholder, type = "text", onChange, value, rightIcon }) => (
  <div className="space-y-2">
    <label htmlFor={id} className="block text-sm font-normal text-[#231F20]">{label}</label>
    <div className="relative">
      {icon && React.cloneElement(icon, { 
        className: `absolute left-3 top-1/2 transform -translate-y-1/2 ${value ? 'text-gray-900' : 'text-gray-400'}`,
        size: 20 
      })}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={`w-full h-[60px] ${icon ? 'pl-10' : 'pl-3'} pr-10 bg-[#EEEEEE] border border-gray-300 rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-gray-400 focus:ring-[#4ABC85] caret-[#4ABC85]`}
      />
      {rightIcon && (
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
          {rightIcon}
        </div>
      )}
    </div>
  </div>
);

export default FindCredentials;
