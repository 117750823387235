import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

function Calendar({ onSelectDate, initialDate, selectedDate, isDateAvailable, availableDates }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (initialDate) {
      setCurrentDate(new Date(initialDate));
    }
  }, [initialDate]);

  const [selectedDay, setSelectedDay] = useState(null);

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

  const today = new Date();
  const months = Array.from(
    { length: 2 }, 
    (_, i) => {
      const date = new Date(today.getFullYear(), today.getMonth() + i);
      return {
        value: date.getMonth(),
        year: date.getFullYear(),
        label: date.toLocaleString('ko-KR', { month: 'long' })
      };
    }
  );

  const handleMonthChange = (e) => {
    const selectedOption = months[parseInt(e.target.value)];
    setCurrentDate(new Date(selectedOption.year, selectedOption.value, 1));
    setSelectedDay(null);
  };

  const handleDateClick = (day) => {
    const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDay(day);
    onSelectDate(selectedDate);
  };

  const renderDay = (day) => {
    const isAvailable = availableDates.some(
      availableDate => availableDate.toDateString() === day.toDateString()
    );

    return (
      <div className="relative">
        <button
          onClick={() => isAvailable && onSelectDate(day)}
          className={`w-10 h-10 rounded-full flex items-center justify-center
            ${isAvailable ? 'hover:bg-gray-100' : 'text-gray-300 cursor-not-allowed'}
            ${selectedDate?.toDateString() === day.toDateString() ? 'bg-[#4ABC85] text-white' : ''}
          `}
          disabled={!isAvailable}
        >
          {day.getDate()}
        </button>
        {isAvailable && (
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-1 h-1 bg-[#4ABC85] rounded-full" />
        )}
      </div>
    );
  };

  return (
    <div className="w-full mb-20">
      <div className="flex justify-between items-center mb-4">
        <div className="relative">
          <select
            value={months.findIndex(m => m.value === currentDate.getMonth() && m.year === currentDate.getFullYear())}
            onChange={handleMonthChange}
            className="w-[130px] h-[40px] appearance-none bg-[#EEEEEE] rounded-2xl py-2 px-4 pr-8 text-sm font-bold"
          >
            {months.map((month, index) => (
              <option key={`${month.year}-${month.value}`} value={index}>
                {month.year} {month.label}
              </option>
            ))}
          </select>
          <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#A3A1A2]" size={20} />
        </div>
      </div>
      <div className="grid grid-cols-7 gap-1">
        {weekdays.map(day => (
          <div key={day} className="font-semibold text-sm mb-2 text-center">{day}</div>
        ))}
        {Array(firstDayOfMonth).fill(null).map((_, index) => (
          <div key={`empty-${index}`} />
        ))}
        {days.map(day => (
          <div key={day} className="flex justify-center items-center">
            {renderDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), day))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Calendar;