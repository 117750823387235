import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Calendar from '../components/Calendar';
import TimeSelector from '../components/TimeSelector';
import { ChevronLeft } from 'lucide-react';
import axios from 'axios';

function BookSchedulePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [existingBooking, setExistingBooking] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const toTimestamp = (date, time) => {
    const [hour, minute] = time.split(':');
    const localDate = new Date(date);
    localDate.setHours(parseInt(hour), parseInt(minute));
    return Math.floor(localDate.getTime() / 1000).toString();
  };

  useEffect(() => {
    if (location.state && location.state.bookedDate && location.state.bookedTime) {
      const bookedDate = new Date(location.state.bookedDate);
      setExistingBooking({
        date: bookedDate,
        time: location.state.bookedTime
      });
      setSelectedDate(bookedDate);
      setSelectedTime(location.state.bookedTime);
    }
  }, [location.state]);

  useEffect(() => {
    if (existingBooking) {
      setIsChanged(
        selectedDate.getTime() !== existingBooking.date.getTime() ||
        selectedTime !== existingBooking.time
      );
    }
  }, [selectedDate, selectedTime, existingBooking]);

  const handleBook = async () => {
    if (selectedDate && selectedTime) {
      try {
        const timestamp = toTimestamp(selectedDate, selectedTime);
        
        if (existingBooking) {
          await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/student/reservation`, {
            rsv_time: timestamp
          });
        } else {
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/student/reservation`, {
            rsv_time: timestamp
          });
        }
        
        navigate('/schedule', { 
          state: { 
            bookedDate: selectedDate, 
            bookedTime: selectedTime 
          } 
        });
      } catch (error) {
        console.error('예약 처리 실패:', error);
        // 에러 처리 필요
      }
    }
  };

  const handleCancel = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/student/reservation`);
      navigate('/schedule', { state: null });
    } catch (error) {
      console.error('예약 취소 실패:', error);
      // 에러 처리 필요
    }
  };

  const getAvailableDates = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 30);
    
    const availableDates = [];
    const currentDate = new Date(tomorrow);
    
    while (currentDate <= maxDate) {
      // 주말(토:6, 일:0) 제외
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        availableDates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return availableDates;
  };

  const isDateAvailable = (date) => {
    if (!date) return false;
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 30);
    
    // 날짜가 내일부터 30일 이내인지 확인
    return (
      date > today &&
      date <= maxDate &&
      // 주말(토:6, 일:0) 제외
      date.getDay() !== 0 &&
      date.getDay() !== 6
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center justify-start p-4 pt-6">
        <div className="w-full max-w-md">
          <div className="flex items-center mb-6">
            <button onClick={() => navigate(-1)} className="mr-2">
              <ChevronLeft size={24} />
            </button>
            <h1 className="text-lg font-semibold">KY TEST 예약하기</h1>
          </div>
          <Calendar 
            onSelectDate={setSelectedDate} 
            initialDate={existingBooking?.date} 
            selectedDate={selectedDate}
            isDateAvailable={isDateAvailable}
            availableDates={getAvailableDates()}
          />
          {selectedDate && (
            <>
              <p className="text-sm font-bold mt-20 mb-5">
                {selectedDate.toLocaleString('ko-KR', { month: 'long', day: 'numeric' })}
              </p>
              <TimeSelector 
                onSelectTime={setSelectedTime} 
                initialTime={existingBooking?.time}
                selectedTime={selectedTime}
              />
            </>
          )}
          {existingBooking ? (
            <div className="flex justify-between mt-[100px]">
              <button
                onClick={handleCancel}
                className="w-[48%] h-[60px] bg-[#F9344B] text-white rounded-2xl font-bold text-lg"
              >
                예약 취소
              </button>
              <button
                onClick={handleBook}
                className={`w-[48%] h-[60px] text-white rounded-2xl font-bold text-lg ${
                  isChanged ? 'bg-[#4ABC85]' : 'bg-[#4ABC85] opacity-10 cursor-not-allowed'
                }`}
                disabled={!isChanged}
              >
                예약 변경
              </button>
            </div>
          ) : (
            <button
              onClick={handleBook}
              className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg mt-[100px]"
              disabled={!selectedDate || !selectedTime}
            >
              예약하기
            </button>
          )}
        </div>
      </main>
    </div>
  );
}

export default BookSchedulePage;