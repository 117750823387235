import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignInPage from './pages/SignInPage';
import SignUpForm from './components/SignUpForm';
import SignUpCompletePage from './pages/SignUpCompletePage';
import SchedulePage from './pages/SchedulePage';
import BookSchedulePage from './pages/BookSchedulePage';
import ResultPage from './pages/ResultPage';
import ResultMapPage from './pages/ResultMapPage';
import FindCredentials from './components/FindCredentials';
// import PreReservationPage from './pages/PreReservationPage';
import MyResultPage from './pages/MyResultPage';
import MyResultMapPage from './pages/MyResultMapPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<PreReservationPage />} /> */}
        <Route path="/" element={<SignInPage />} />
        {/* <Route path="/signin" element={<SignInPage />} /> */}
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/signup-complete" element={<SignUpCompletePage />} />
        <Route path="/schedule" element={<SchedulePage />} />
        <Route path="/schedule/book" element={<BookSchedulePage />} />
        <Route path="/result/:code" element={<ResultPage />} />
        <Route path="/result/:code/map" element={<ResultMapPage />} />
        <Route path="/find-credentials" element={<FindCredentials />} />
        <Route path="/myresult/:code" element={<MyResultPage />} />
        <Route path="/myresult/:code/map" element={<MyResultMapPage />} />
      </Routes>
    </Router>
  );
}

export default App;
