import React, { useState, useEffect } from 'react';
import { EyeOff, Eye, Mail, Lock, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SignInForm = () => {
  const [saveId, setSaveId] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const isFormValid = email.trim() !== '' && password.trim() !== '';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/student/signin`,
        {
          student_email: email,
          student_pw: password
        }
      );

      if (response.data.status === 'success') {
        localStorage.setItem('access_token', response.data.data.access_token);
        localStorage.setItem('student_email', response.data.data.student_email);
        localStorage.setItem('student_name', response.data.data.student_name);
        
        navigate('/schedule');
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            setError('이메일 또는 비밀번호가 올바르지 않습니다.');
            break;
          case 423:
            setError('계정이 차단되었습니다.');
            break;
          default:
            setError('로그인 중 오류가 발생했습니다.');
        }
      }
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div className="space-y-2">
        <label htmlFor="email" className="block text-sm font-medium text-[#231F20]">이메일</label>
        <div className="relative">
          <Mail className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2]`} size={20} />
          <input
            id="email"
            type="text"
            placeholder="이메일을 입력해 주세요"
            className={`w-full h-[60px] pl-10 pr-3 bg-[#EEEEEE] border border-[#EEEEEE] rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-[#A3A1A2] focus:ring-[#4ABC85] caret-[#4ABC85] ${email ? 'text-[#231F20]' : 'text-[#A3A1A2]'}`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          />
        </div>
      </div>
      <div className="space-y-2">
        <label htmlFor="password" className="block text-sm font-medium text-[#231F20]">비밀번호</label>
        <div className="relative">
          <Lock className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2]`} size={20} />
          <input
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder="비밀번호를 입력해 주세요"
            className={`w-full h-[60px] pl-10 pr-3 bg-[#EEEEEE] border border-[#EEEEEE] rounded-2xl text-sm font-regular focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-[#A3A1A2] focus:ring-[#4ABC85] caret-[#4ABC85] ${password ? 'text-[#231F20]' : 'text-[#A3A1A2]'}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {showPassword ? (
            <Eye onClick={() => setShowPassword(false)} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2] opacity-40 cursor-pointer" size={20} />
          ) : (
            <EyeOff onClick={() => setShowPassword(true)} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2] opacity-40 cursor-pointer" size={20} />
          )}
        </div>
      </div>
      <div style={{ marginTop: '20px' }} className="flex items-center justify-between">
        <div className="flex items-center">
          <div
            onClick={() => setSaveId(!saveId)}
            className={`w-[22px] h-[22px] border rounded flex items-center justify-center cursor-pointer ${
              saveId ? 'bg-[#4ABC85] border-[#4ABC85]' : 'bg-[#D9D9D9] border-[#D9D9D9]'
            }`}
          >
            <Check 
              size={16} 
              color="white"
            />
          </div>
          <label
            htmlFor="saveId"
            className="ml-2 text-sm font-normal text-[#231F20] opacity-40 tracking-tighter cursor-pointer"
            style={{ letterSpacing: '-2.5%' }}
          >
            아이디 저장
          </label>
        </div>
        {/* <button
          type="button"
          onClick={() => navigate('/find-credentials')}
          className="text-sm font-normal text-[#231F20] opacity-40 tracking-tighter cursor-pointer"
          style={{ letterSpacing: '-2.5%' }}
        >
          이메일 · 비밀번호 찾기
        </button> */}
      </div>
      <div style={{ marginTop: '60px' }}>
        <button 
          type="submit" 
          className={`w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg ${isFormValid ? '' : 'opacity-40 cursor-not-allowed'}`}
          disabled={!isFormValid}
        >
          로그인
        </button>
      </div>
      <div style={{ marginTop: '108px' }} className="text-center text-sm">
        <span className="text-gray-600">아직 회원이 아니신가요? </span>
        <button
          type="button"
          onClick={() => navigate('/signup')}
          className="text-[#4ABC85] font-semibold underline"
        >
          회원가입
        </button>
      </div>
      {error && (
        <div className="text-red-500 text-sm mt-2 text-center">
          {error}
        </div>
      )}
    </form>
  );
};

export default SignInForm;
