import React, { useState } from 'react';
import { X, User, LogOut, Eye, EyeOff, Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ProfileModal = ({ isOpen, onClose, onLogout, studentName, studentEmail }) => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [error, setError] = useState('');

  const handleLogout = () => {
    onLogout();
    onClose();
    navigate('/');
  };

  const handleChangePassword = async () => {
    try {
      setError('');
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/student/info`, {
        prev_pw: currentPassword,
        new_pw: newPassword,
      });

      if (response.data.status === 'success') {
        localStorage.setItem('access_token', response.data.data.access_token);
        onClose();
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setError('기존 비밀번호가 일치하지 않습니다.');
      } else {
        setError('비밀번호 변경 중 오류가 발생했습니다.');
      }
    }
  };

  const isPasswordValid = currentPassword.length > 0 && newPassword.length > 0 && 
    newPassword === confirmPassword;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white p-6 rounded-2xl w-[72%] max-w-sm" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold text-[#231F20]">프로필 설정</h2>
          <X onClick={onClose} className="cursor-pointer" />
        </div>
        
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <div className="w-12 h-12 bg-[#EEEEEE] rounded-full flex items-center justify-center mr-3">
              <User className="w-6 h-6 text-[#A3A1A2]" />
            </div>
            <div>
              <p className="text-sm font-bold text-[#231F20]">{studentName}</p>
              <p className="text-xs text-[#A3A1A2]">{studentEmail}</p>
            </div>
          </div>
          <button 
            onClick={handleLogout}
            className="flex items-center justify-center w-[90px] h-[40px] rounded-[10px] bg-[#EEEEEE] text-xs font-bold text-[#231F20]"
          >
            <LogOut className="w-4 h-4 mr-1 text-[#231F20]" />
            로그아웃
          </button>
        </div>

        <div className="space-y-2 mb-4">
          <label htmlFor="currentPassword" className="block text-sm font-normal text-[#231F20]">현재 비밀번호</label>
          <div className="relative">
            <Lock className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${currentPassword ? 'text-gray-900' : 'text-gray-400'}`} size={20} />
            <input
              id="currentPassword"
              type={showCurrentPassword ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={`w-full h-[60px] pl-10 pr-10 bg-[#EEEEEE] border border-gray-300 rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-gray-400 focus:ring-[#4ABC85] caret-[#4ABC85]`}
              placeholder="현재 비밀번호를 입력해 주세요"
            />
            <button
              type="button"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showCurrentPassword ? <Eye className="w-5 h-5 text-gray-400" /> : <EyeOff className="w-5 h-5 text-gray-400" />}
            </button>
          </div>
          <label htmlFor="newPassword" className="block text-sm font-normal text-[#231F20]">비밀번호 변경</label>
          <div className="relative">
            <Lock className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${newPassword ? 'text-gray-900' : 'text-gray-400'}`} size={20} />
            <input
              id="newPassword"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={`w-full h-[60px] pl-10 pr-10 bg-[#EEEEEE] border border-gray-300 rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-gray-400 focus:ring-[#4ABC85] caret-[#4ABC85]`}
              placeholder="새 비밀번호를 입력해 주세요"
            />
            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showNewPassword ? <Eye className="w-5 h-5 text-gray-400" /> : <EyeOff className="w-5 h-5 text-gray-400" />}
            </button>
          </div>
          <div className="relative">
            <Lock className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${confirmPassword ? 'text-gray-900' : 'text-gray-400'}`} size={20} />
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={`w-full h-[60px] pl-10 pr-10 bg-[#EEEEEE] border border-gray-300 rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-gray-400 focus:ring-[#4ABC85] caret-[#4ABC85]`}
              placeholder="새 비밀번호를 다시 입력해 주세요"
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showConfirmPassword ? <Eye className="w-5 h-5 text-gray-400" /> : <EyeOff className="w-5 h-5 text-gray-400" />}
            </button>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>

        <button
          onClick={handleChangePassword}
          disabled={!isPasswordValid}
          className={`w-full h-[60px] text-white rounded-xl ${
            isPasswordValid 
              ? "bg-[#4ABC85] cursor-pointer" 
              : "bg-[#4ABC85] opacity-10 cursor-not-allowed"
          }`}
        >
          비밀번호 변경하기
        </button>
      </div>
    </div>
  );
};

export default ProfileModal;