import React from 'react';
import { useNavigate } from 'react-router-dom';
import logoChecked from '../assets/logo_checked.png';
import Header from '../components/Header';

const SignUpCompletePage = () => {
  const navigate = useNavigate();

  const handleDiagnosisClick = () => {
    navigate('/');
  };

  return (
    <>
      {/* <Header /> */}
      <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
        <div className="w-full max-w-[384px] space-y-6">
          <div className="flex justify-center">
            <img src={logoChecked} alt="Checked Logo" className="h-[75px] w-[115px]" />
          </div>
          <div className="space-y-2 text-center" style={{ marginTop: '60px' }}>
            <h1 className="text-[18px] font-bold">가입이 완료되었어요</h1>
            <p className="text-[14px] text-[#231F20] opacity-60">
              KY HUB를 통해 내 수학 실력을 진단하고<br />
              학습 목표를 설정해보세요
            </p>
          </div>
          <div style={{ marginTop: '60px' }}>
            <button
              onClick={handleDiagnosisClick}
              className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg"
            >
              실력 진단 받기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpCompletePage;