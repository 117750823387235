import React, { useEffect, useRef } from 'react';

function ProgressBar({ progress }) {
  const progressCanvasRef = useRef(null);
  
  useEffect(() => {
    const canvas = progressCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 540;
    const height = 80;
    
    // Canvas 초기화
    ctx.clearRect(0, 0, width, height);
    
    // 진행률 계산 (소수점 반올림)
    const progressPercent = Math.round(progress * 100);
    
    // 배경 바 그리기 (회색)
    ctx.beginPath();
    ctx.fillStyle = '#EEEEEE';
    ctx.roundRect(0, 0, width, height, 32);
    ctx.fill();
    
    // 진행률 바 그리기 (초록색)
    ctx.beginPath();
    ctx.fillStyle = '#4ABC85';
    let progressWidth = (width * progressPercent) / 100;
    
    // 진행률이 30% 미만일 경우 30%로 고정
    if (progressWidth < width * 0.3) {
      progressWidth = width * 0.3;
    }
    
    ctx.roundRect(0, 0, progressWidth, height, 32);
    ctx.fill();
    
    // 진행률 텍스트 그리기
    ctx.fillStyle = '#FFFFFF';
    ctx.textAlign = 'center';
    
    const textX = progressWidth / 2;
    const textY = height / 2 + 16;
    
    // 숫자 부분 (24px, bold)
    ctx.font = 'bold 48px sans-serif';
    ctx.fillText(progressPercent, textX - 20, textY);
    
    // % 기호 부분 (12px, normal)
    ctx.font = '24px sans-serif';
    ctx.fillText('%', textX + 20, textY);
  }, [progress]);

  return (
    <div className="mt-[40px]">
      <p className="text-[12px] font-semibold text-[#231F20] text-left mb-2 text-center ">
        다음 등급까지 목표 도달량
      </p>
      <div className="flex justify-center">
        <canvas 
          ref={progressCanvasRef}
          width={540}
          height={80}
          className="w-[290px]"
        />
      </div>
    </div>
  );
}

export default ProgressBar;
