import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Header from '../components/Header';
import logoVacant from '../assets/logo_vacant.png';
import axios from 'axios';

function SchedulePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [bookedSchedule, setBookedSchedule] = useState(null);

  const fromTimestamp = (timestamp) => {
    const date = new Date(parseInt(timestamp) * 1000);
    return {
      date: date,
      time: date.toLocaleTimeString('ko-KR', { 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: false 
      })
    };
  };

  useEffect(() => {
    const checkMainStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/student/main`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        if (response.status === 204) {
          setBookedSchedule(null);
          return;
        }

        switch (response.data.status_code) {
          case 200:
            if (response.data.data.rsv_time) {
              const { date, time } = fromTimestamp(response.data.data.rsv_time);
              setBookedSchedule({
                bookedDate: date,
                bookedTime: time
              });
            }
            break;
          case 202:
            navigate(`/myresult/${response.data.data.exam_id}`);
            break;
          default:
            localStorage.clear();
            navigate('/');
        }
      } catch (error) {
        console.error('상태 확인 실패:', error);
        localStorage.clear();
        navigate('/');
      }
    };

    checkMainStatus();
  }, [navigate]);

  const formatDate = (date) => {
    const options = { month: 'long', day: 'numeric', weekday: 'long' };
    return new Date(date).toLocaleDateString('ko-KR', options);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center p-4">
        <div className="w-full max-w-md flex flex-col h-full">
          {bookedSchedule ? (
            <>
              <h2 className="text-lg font-bold mb-2 text-left">KY TEST 일정을<br />확인해보세요</h2>
              <p className="text-sm text-[#231F20] opacity-60 mb-6 text-left">내 일정을 확인하고 변경할 수 있어요</p>
              <div className="w-full bg-[#F8F8F8] rounded-2xl p-4 flex justify-between items-center mb-2">
                <div>
                  <p className="text-sm font-bold text-[#231F20]">{formatDate(bookedSchedule.bookedDate)}</p>
                  <p className="text-xs text-[#231F20] opacity-60">{bookedSchedule.bookedTime}</p>
                </div>
                <button 
                  className="w-[80px] h-[40px] bg-[#EEEEEE] rounded-2xl text-xs text-[#231F20] opacity-60"
                  onClick={() => navigate('/schedule/book', { state: { bookedDate: bookedSchedule.bookedDate, bookedTime: bookedSchedule.bookedTime } })}
                >
                  예약 변경하기
                </button>
              </div>
              {/* <p className="text-xs text-[#231F20] opacity-60 self-end">예약 변경은 하루에 1회만 가능합니다</p> */}
              {/* <Link to="/result" className="text-xs text-[#4ABC85] self-end mt-4">
                결과 보기
              </Link> */}
            </>
          ) : (
            <>
              <div className="flex-grow flex justify-center items-start pt-20">
                <img 
                  src={logoVacant} 
                  alt="Vacant Logo" 
                  className="w-[200px] mb-[100px]"
                />
              </div>
              <div className="flex justify-center pb-20">
                <button
                  className="w-full max-w-[384px] h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg"
                  onClick={() => navigate('/schedule/book')}
                >
                  KY TEST 예약하기
                </button>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default SchedulePage;