import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Share, CreditCard, X, Settings, TabletSmartphone } from 'lucide-react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import Percentile from '../components/result/percentile';
import BarChart from '../components/result/barchart';
import ProgressBar from '../components/result/progressbar';
import { useNavigate, useParams, Navigate } from 'react-router-dom';


function ResultPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState(null);
  const canvasRef = useRef(null);
  const progressCanvasRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/result/${code}`;
        
        const response = await fetch(endpoint);
        const json = await response.json();
        
        if (json.status === 'success' && isMounted) {
          setAnalysis(json.data.analysis);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code]);

  const graphData = useMemo(() => {
    if (!analysis) return null;

    const timeData = analysis.time_remain_reference.map(time => Math.floor(time / 60));

    const getGradeText = (grade) => {
      return grade === 0 ? "만점" : `${grade}등급`;
    };

    const getTimeDataIndex = (targetGrade, offset = 0) => {
      const index = targetGrade + offset;
      return Math.min(Math.max(index, 0), timeData.length - 1); // 배열 범위를 벗어나지 않도록
    };

    return {
      position: analysis.target_grade,
      data: [
        { grade: analysis.target_grade + 2, time: timeData[getTimeDataIndex(analysis.target_grade, 1)], label: getGradeText(analysis.target_grade + 2) },
        { grade: analysis.target_grade + 1, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade + 1) },
        { grade: analysis.target_grade, time: Math.floor((analysis.remain_time) / 60), label: "현재 위치" },
        { grade: analysis.target_grade, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade) }
      ]
    };
  }, [analysis]);

  const getGoalTime = () => {
    if (!analysis) return 0;
    return Math.floor(analysis.remain_time / 60);
  };


const handleShare = () => {
  const currentUrl = window.location.href;
  
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    // Safari 전용 복사 로직
    const tempInput = document.createElement('input');
    tempInput.value = currentUrl;
    tempInput.style.cssText = 'position: absolute; left: -9999px; top: -9999px';
    document.body.appendChild(tempInput);
    tempInput.focus();
    tempInput.setSelectionRange(0, 99999);
    
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        setToastMessage('URL이 클립보드에 복사되었습니다.');
      } else {
        throw new Error('복사 실패');
      }
    } catch (err) {
      setToastMessage('URL 복사에 실패했습니다. 직접 선택해주세요.');
    } finally {
      document.body.removeChild(tempInput);
    }
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  } else {
    // 다른 브라우저용 복사 로직
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setToastMessage('URL이 클립보드에 복사되었습니다.');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((err) => {
        console.error('클립보드 복사 실패:', err);
        setToastMessage('URL 복사에 실패했습니다.');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      });
  }
};


  if (!analysis) return <div>로딩중...</div>;

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* <Header /> */}
      <main className="flex-grow flex flex-col items-center pt-[12px]">
        <img
          src={logoAnalysis}
          alt="Analysis Logo"
          className="w-[121px] h-[26px] mb-[40px]"
        />

        <div className="w-[384px] space-y-[20px] mb-[40px]">
          <div className="w-full border border-gray-200 rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            <p className="text-center text-[12px] font-semibold mb-[14px]">
              {analysis.target_grade}등급까지 이만큼 남았어요
            </p>
            <div className="flex justify-center items-center">
              <span className="text-[48px] font-bold text-[#231F20]">{getGoalTime()}</span>
              <span className="text-[12px] font-semibold text-[#A3A1A2] mt-[24px]">시간</span>
            </div>

            <div className="mt-[30px]">
              {graphData && <BarChart graphData={graphData} />}
            </div>

            <ProgressBar progress={analysis.progress} />
          </div>

          <Percentile percentage={analysis.percentage} />

          <div
            className="w-full border border-gray-200 rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]"
          >
            <p className="text-[12px] font-semibold text-[#231F20] mb-[10px]">
              이 부분은 보완이 필요해요
            </p>

            {analysis?.priority_sector.map((item, index) => (
              <div key={index} className="h-auto bg-[#F8F8F8] rounded-2xl p-4 mb-[10px] last:mb-0">
                <div className="flex items-center gap-2 mb-1">
                  <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                    <span className="text-[8px] font-semibold text-white">
                      {item.tree_name.replace(/\([^)]*\)/g, '').trim()}
                    </span>
                  </div>
                  <span className="text-[12px] font-medium text-[#7B7979]">
                    {item.parent_name}
                  </span>
                </div>
                <p className="text-[14px] font-semibold text-[#231F20]">
                  {item.comp_name.replace(/\([^)]*\)/g, '').trim()}
                </p>
              </div>
            ))}
            
            <button
              onClick={() => {
                  navigate(`/result/${code}/map`);
              }}
              className="w-full h-[40px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575] mt-4"
            >
              나의 폼 확인하기
            </button>
          </div>
        </div>
        {/* 버튼 그룹 */}
        <div className="w-[384px] flex gap-[20px] mb-[40px]">
          <button 
            onClick={handleShare}
            className="flex-1 h-[60px] flex items-center justify-center space-x-2 bg-[#EEEEEE] text-[#7B7979] rounded-2xl hover:bg-[#E5E5E5]"
          >
            <Share size={20} />
            <span className="font-bold text-sm">공유하기</span>
          </button>
          <button 
            onClick={() => navigate(`/signup?referral=${code}`)}
            className="flex-[2] h-[60px] flex items-center justify-center space-x-2 bg-[#4ABC85] text-[#fff] rounded-2xl hover:bg-[#3da575]"
          >
            <TabletSmartphone size={20} />
            <span className="font-bold text-sm">테스트 예약하기</span>
          </button>
        </div>
      </main>
      
      {showToast && (
        <div className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 bg-[#231F20] text-white px-4 py-3 rounded-lg shadow-lg">
          <div className="flex items-center space-x-2">
            <span className="text-sm">{toastMessage}</span>
            <button 
              onClick={() => setShowToast(false)}
              className="ml-2"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResultPage;