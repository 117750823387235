import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import axios from 'axios';

function MyResultMapPage() {
  const navigate = useNavigate();
  const { code } = useParams();
  const [data, setData] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState('d4');

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const name = localStorage.getItem('student_name');
    const email = localStorage.getItem('student_email');

    if (!token || !name || !email) {
      navigate('/', { replace: true });
      return;
    }

    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/resultmap`,
          {
            params: {
              exam_id: code
            },
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        
        if (response.data.status === 'success' && isMounted) {
          setData(response.data.data.field_info);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code, navigate]);

  const colors = {
    100: 'bg-[#F9344B]',
    // 101: 'bg-[#F9344B] opacity-10',
    101: 'bg-[#FDCECE] opacity-70' ,
    200: 'bg-[#4ABC85]',
    // 201: 'bg-[#4ABC85] opacity-10',
    201: 'bg-[#B7E4CE] opacity-70',
    500: 'bg-[#FAEECE]',
    0: 'after:content-["×"]'
  };

  const renderStatusBox = (status) => {
    return (
      <div className={`w-6 h-6 rounded-md rounded ${colors[status] || 'bg-gray-200'}`} />
    );
  };

  // 각 레벨별 데이터 추출
  const getLevelData = () => {
    if (!data) return [];

    const levelMap = {
      d1: {
        title: '대단원',
        data: data.d1_y_axis || []
      },
      d2: {
        title: '소단원',
        data: data.d2_y_axis || []
      },
      d3: {
        title: '대유형',
        data: data.d3_y_axis || []
      },
      d4: {
        title: '중유형',
        data: data.d4_y_axis || []
      }
    };

    return levelMap[selectedLevel]?.data?.map(item => ({
      name: item[1]
        .split('_(D5)')[0]
        .replace(/\([^)]*\)/g, '')
        .trim(),
      status: item[3]
    })) || [];
  };

  // 레벨 순환 처리
  const getNextLevel = (currentLevel) => {
    const levels = {
      'd1': 'd2',
      'd2': 'd3',
      'd3': 'd4',
      'd4': 'd1'
    };
    return levels[currentLevel];
  };

  // 이전 레벨 가져오기 함수 수정
  const getPrevLevel = (currentLevel) => {
    const levels = {
      'd1': 'd4',
      'd2': 'd1',
      'd3': 'd2',
      'd4': 'd3'
    };
    return levels[currentLevel];
  };

  // 레벨별 표시 텍스트
  const levelTitles = {
    'd4': '중유형',
    'd3': '대유형',
    'd2': '소단원',
    'd1': '대단원'
  };

  // 뒤로가기 버튼 클릭 핸들러
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center pt-[12px]">
        <div className="w-[384px] flex items-center mb-4">
          <button 
            onClick={handleBack} 
            className="flex items-center text-gray-600 hover:text-gray-800"
          >
            <ChevronLeft size={24} />
            <span className="text-sm font-medium"></span>
          </button>
        </div>
        <div className="text-xl font-bold mb-10">나의 폼</div>
        {data && (
          <div className="w-[384px] text-sm font-medium text-center mb-[40px]">
            <span className=" px-[40px] py-[12px] rounded-[16px] border-[#000] border-[1px]">
              {data.tree_name}
            </span>
          </div>
        )}
        <div className="flex gap-4 mb-[22px] text-xs">
        <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded bg-[#B7E4CE] opacity-70"></div>
            <span>Likely Correct</span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded bg-[#4ABC85]"></div>
            <span>Correct</span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded bg-[#FDCECE] opacity-70"></div>
            <span>Likely Incorrect</span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded bg-[#F9344B]"></div>
            <span>Incorrect</span>
          </div>
        </div>
        <div 
          className="w-[384px] overflow-x-auto mb-[40px]" 
          style={{ 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <style jsx>{`
            div::-webkit-scrollbar {
              display: none;
            }
          `}</style>
          <table className="w-full text-xs">
            <thead>
              <tr>
                <th className="px-2 py-1 text-center">
                  <div className="flex items-center justify-center gap-3">
                    <button 
                      onClick={() => setSelectedLevel(getPrevLevel(selectedLevel))}
                      className="text-[10px] text-gray-400 hover:text-gray-600 disabled:opacity-30"
                      disabled={selectedLevel === 'd1'}
                    >
                      &lt;
                    </button>
                    <span className="text-[10px] text-gray-900">
                      {levelTitles[selectedLevel]}
                    </span>
                    <button 
                      onClick={() => setSelectedLevel(getNextLevel(selectedLevel))}
                      className="text-[10px] text-gray-400 hover:text-gray-600 disabled:opacity-30"
                      disabled={selectedLevel === 'd4'}
                    >
                      &gt;
                    </button>
                  </div>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 1</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 2</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 3</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 4</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 5</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {getLevelData().map((item, index) => (
                <tr key={index}>
                  <td className="px-2 py-1 text-[10px] text-left">
                    {item.name}
                  </td>
                  {item.status.map((status, statusIndex) => (
                    <td key={statusIndex} className="px-1 py-1 text-center">
                      <div className="flex justify-center">
                        {renderStatusBox(status)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default MyResultMapPage;