import React, { useState, useEffect } from 'react';
import { Bell, User } from 'lucide-react';
import logoHeader from '../assets/logo_header.png'; // 이미지 경로를 적절히 조정하세요
import ProfileModal from './ProfileModal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Header() {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const navigate = useNavigate();

  const handleLogout = () => {
    // 로컬 스토리지 완전 초기화
    localStorage.clear(); // 모든 항목 제거
    
    // 상태 초기화
    setStudentName('');
    setStudentEmail('');
    
    // 홈페이지로 리다이렉트
    navigate('/', { replace: true }); // replace: true를 사용하여 뒤로가기 방지
  };

  useEffect(() => {
    const name = localStorage.getItem('student_name');
    const email = localStorage.getItem('student_email');
    const token = localStorage.getItem('access_token');
    
    // 필요한 정보가 없으면 로그인 페이지로 리다이렉트
    if (!name || !email || !token) {
      navigate('/', { replace: true });
      return;
    }
    
    setStudentName(name);
    setStudentEmail(email);
  }, [navigate]); // navigate를 의존성 배열에 추가

  // axios 인터셉터 설정
  useEffect(() => {
    // 요청 인터셉터
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // 응답 인터셉터 추가
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          // 토큰 만료 시 처리
          localStorage.clear();
          navigate('/', { replace: true });
        }
        return Promise.reject(error);
      }
    );
  }, [navigate]); // navigate를 의존성 배열에 추가

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  return (
    <header className="w-full bg-white">
      <div className="max-w-[750px] mx-auto flex justify-between items-center p-4">
        <div className="px-4">
          <img src={logoHeader} alt="로고" className="h-8" />
        </div>
        <div className="flex items-center space-x-4 px-4">
          <Bell className="w-6 h-6 text-gray-600" />
          <User className="w-6 h-6 text-gray-600 cursor-pointer" onClick={toggleProfileModal} />
        </div>
        <ProfileModal 
          isOpen={isProfileModalOpen}  
          onClose={toggleProfileModal}
          onLogout={handleLogout}
          studentName={studentName}
          studentEmail={studentEmail}
        />
      </div>
    </header>
  );
}

export default Header;